import React from "react";
import "bootstrap/dist/css/bootstrap.css";

import UserMenu from "./UserMenu";

const Header = (props) => {
  return (
    <div
      id="header-nav"
      className="d-flex align-items-center justify-around w-full bg-blue-950 text-3xl px-4 py-2"
    >
      {/* <div id="logo-container">
            <div className="text-left align-items-center d-flex">
              <img
                id="redeye-logo"
                src="/static/base/images/RedeyeLogo.png"
                alt="Redeye logo"
              />
            </div>
          </div> 
      */}
      <div className="text-center align-items-center">
        <a
          className="no-underline text-yellow-400 hover:text-yellow-600"
          href="/"
        >
          <img
            className="inline-block px-3 shadow-lg shadow-cyan-500/5"
            src="/static/base/images/favicon.ico"
          />
          Redeye Cloud
        </a>
      </div>
      <UserMenu {...props} />
    </div>
  );
};

export default Header;
