import React, { useState, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ManagementContext } from "../Contexts/ManagementContext";
import $ from "jquery";

const AddSiteToGroup = (props) => {
  const {
    siteList,
    siteGroupList,
    toggle,
    onCancel,
    selectedGroup,
    refreshList,
    toggleModal,
    csrfToken,
    refreshGroupList,
  } = props;
  const { user } = useContext(ManagementContext);
  const siteGroupIDs = siteGroupList.map((group) => group.id);
  // Filter the siteList based on whether the group field is in the collected IDs
  let customSiteList = siteList.filter((site) =>
    siteGroupIDs.includes(site.group)
  );
  if (user.is_superuser) customSiteList = siteList;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSiteInTable, setSelectedSiteInTable] = useState(null);
  const [sortConfig, setSortConfig] = useState(null);

  const { control, handleSubmit, formState, setValue } = useForm();

  const handleAddSiteToGroupSubmit = (item) => {
    toggleModal();

    const selectedGroupObject = siteGroupList.find(
      (group) => group.name === selectedGroup
    );

    const site = siteList.find(
      (customSiteList) => customSiteList.id === parseInt(item.site, 10)
    );

    if (site) {
      const { name, latitude, longitude } = site;

      item.group = selectedGroupObject.id;

      // Confirmation popup for reassigning group
      if (site.group && site.group !== selectedGroupObject.id) {
        const proceed = window.confirm(
          `Site is already in group ${site.group_name}. Do you want to move it to group ${selectedGroup}?`
        );
        if (!proceed) {
          return;
        }
      }

      $.ajax({
        type: "PUT",
        url: `/redeye/api/sites/${site.id}/`,
        data: {
          id: site.id,
          name: name,
          latitude: latitude,
          longitude: longitude,
          group: item.group,
        },
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        dataType: "json",
        success: function (data) {
          refreshList();
          refreshGroupList();
        },
      });
    }
  };

  const handleRowClick = (site) => {
    if (selectedSiteInTable && selectedSiteInTable.id === site.id) {
      setSelectedSiteInTable(null);
      setValue("site", "");
      setSearchTerm("");
    } else {
      setSelectedSiteInTable(site);
      setValue("site", site.id);
      setSearchTerm(site.name);
    }
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const selectedGroupObject = siteGroupList.find(
    (group) => group.name === selectedGroup
  );

  // Filter based on group and search term
  const filteredSiteList = siteList
    .filter((site) => site.group != selectedGroupObject.id)
    .filter((site) =>
      site.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  // Sort the filtered list
  let sortedSiteList = filteredSiteList ? [...filteredSiteList] : [];
  if (sortConfig !== null) {
    sortedSiteList.sort((a, b) => {
      const valueA = a[sortConfig.key] || "";
      const valueB = b[sortConfig.key] || "";

      if (valueA < valueB) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  const style = {
    myModal: {
      maxWidth: "600px",
      width: "80%",
      height: "80%",
    },
  };

  return (
    <Modal isOpen={true} toggle={toggle} style={style.myModal}>
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Add Site to the Group
      </ModalHeader>
      <form onSubmit={handleSubmit((data) => handleAddSiteToGroupSubmit(data))}>
        <ModalBody>
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="group" style={{ width: "17%" }}>
                <b>Site Group : </b>
              </Label>
              <Controller
                name="group"
                control={control}
                defaultValue={selectedGroup}
                style={{ width: "200%" }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="input"
                    placeholder="Group"
                    disabled={false}
                    value={selectedGroup}
                  />
                )}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Label for="search" style={{ width: "20%" }}>
                <b>Search : </b>
              </Label>
              <Input
                type="text"
                style={{ width: "180%" }}
                name="search"
                id="search"
                placeholder="Search Site"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (e.target.value === "") setSelectedSiteInTable(null);
                }}
              />
            </div>
          </FormGroup>

          <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
            <Table hover>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  borderTop: "2px solid #000",
                  borderBottom: "3px solid #000",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th onClick={() => requestSort("name")}>
                    Site Name{" "}
                    {sortConfig?.key === "name"
                      ? sortConfig.direction === "ascending"
                        ? "↑"
                        : "↓"
                      : ""}
                  </th>
                  <th onClick={() => requestSort("group_name")}>
                    Group Name{" "}
                    {sortConfig?.key === "group_name"
                      ? sortConfig.direction === "ascending"
                        ? "↑"
                        : "↓"
                      : ""}
                  </th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {sortedSiteList &&
                  sortedSiteList.map((site) => (
                    <tr
                      key={site.id}
                      style={
                        selectedSiteInTable?.id === site.id
                          ? { backgroundColor: "lightblue" }
                          : {}
                      }
                    >
                      <td>{site.name}</td>
                      <td>{site.group_name ? site.group_name : "---"}</td>
                      <td>
                        <Button
                          onMouseOver={(e) =>
                            (e.target.style.backgroundColor = "#00008B")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.backgroundColor =
                              selectedSiteInTable?.id === site.id
                                ? "#00008B"
                                : "")
                          }
                          onClick={() => handleRowClick(site)}
                        >
                          {selectedSiteInTable?.id === site.id
                            ? "Selected"
                            : "Select"}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
          {formState.errors.site && <span>This field is required</span>}
        </ModalBody>

        <ModalFooter className="d-flex flex-row justify-content-between">
          <Button color="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="success" type="submit">
            Save
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddSiteToGroup;
