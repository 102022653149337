import React, { useState, useContext, useMemo, useEffect } from "react";
import {
  faCaretRight,
  faCaretUp,
  faCaretDown,
  faPlus,
  faBatteryEmpty,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBatteryFull,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "reactstrap";
import SiteStatusCircle from "./SiteStatusCircle.js";
import { SiteNavigationContext } from "../Contexts/SiteNavigationContext";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const Sites = (props) => {
  const {
    siteList,
    refreshList,
    siteSelectHandler,
    siteGroupList,
    user,
    siteListLoading,
    handleSiteSubmit,
  } = props;

  const {
    highlightedSite,
    setHighlightedSite,
    expandedGroups,
    setExpandedGroups,
  } = useContext(SiteNavigationContext);

  const [selectedSite, setSelectedSite] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.id) {
      setHighlightedSite(+params.id);
    }
  }, [params]);

  const openSiteInfo = (siteId) => {
    setHighlightedSite(siteId);
    siteSelectHandler(siteId);
  };

  const toggleGroup = (group) => {
    if (expandedGroups.includes(group)) {
      const selectedSiteInGroup = siteList.find(
        (site) =>
          site.group_name === group &&
          selectedSite &&
          selectedSite.id === site.id
      );

      if (selectedSiteInGroup) {
        setSelectedSite(null);
        setExpandedGroups(expandedGroups.filter((g) => g !== group));
      } else {
        setExpandedGroups(expandedGroups.filter((g) => g !== group));
      }
    } else {
      setExpandedGroups([...expandedGroups, group]);
    }
  };

  const handleAddSiteClick = (group) => {
    const newSiteName = prompt("Enter the name for the new site:");
    if (newSiteName === null) {
      return;
    }

    if (newSiteName.trim() === "") {
      alert("Site name cannot be empty.");
      return;
    }

    const newSite = {
      name: newSiteName,
      group: groupNameToGroupMap.get(group),
    };
    handleSiteSubmit(newSite);
    refreshList();
  };

  const groupNameToGroupMap = useMemo(() => {
    const map = new Map();

    siteGroupList.forEach((siteGroup) => {
      map.set(siteGroup.name, siteGroup.id);
    });

    siteList.forEach((site) => {
      if (site.group_name && site.group) {
        map.set(site.group_name, site.group);
      }
    });

    return map;
  }, [siteList, siteGroupList]);

  let groups = [];

  const siteGroupsFromList = siteList.map((site) => site.group_name);
  const siteGroupsFromGroupList = siteGroupList.map(
    (siteGroup) => siteGroup.name
  );

  groups = [
    ...new Set([...siteGroupsFromList, ...siteGroupsFromGroupList]),
  ].sort((a, b) => {
    if (a === null) return 1;
    if (b === null) return -1;
    return a.localeCompare(b);
  });

  const formatBatteryPercentage = (site) => {
    if (
      site?.endpoint_status?.latest_endpoint_diagnostics?.battery_percentage ===
      undefined
    ) {
      return "NA";
    }
    const batteryPercentage = Math.floor(
      parseFloat(
        site.endpoint_status.latest_endpoint_diagnostics.battery_percentage
      )
    );

    let batteryColorToUse =
      batteryPercentage > 20 ? "text-green-500" : "text-red-500";

    let batteryIconToUse = faBatteryEmpty;
    if (batteryPercentage > 75) {
      batteryIconToUse = faBatteryFull;
    } else if (batteryPercentage > 50) {
      batteryIconToUse = faBatteryThreeQuarters;
    } else if (batteryPercentage > 25) {
      batteryIconToUse = faBatteryHalf;
    } else if (batteryPercentage > 10) {
      batteryIconToUse = faBatteryQuarter;
    }

    return (
      <span className={`flex items-center ${batteryColorToUse} font-bold`}>
        <FontAwesomeIcon
          icon={batteryIconToUse}
          className="w-3 h-3 rotate-270"
        />
        {batteryPercentage}%
      </span>
    );
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedSites = (sites) => {
    return sites.sort((a, b) => {
      if (a[sortConfig.key] === null) return 1;
      if (b[sortConfig.key] === null) return -1;

      let valueA, valueB;

      switch (sortConfig.key) {
        case "battery":
          valueA =
            a?.endpoint_status?.latest_endpoint_diagnostics
              ?.battery_percentage || 0;
          valueB =
            b?.endpoint_status?.latest_endpoint_diagnostics
              ?.battery_percentage || 0;
          break;
        case "status":
          valueA = a.endpoint_status?.status || "";
          valueB = b.endpoint_status?.status || "";
          break;
        default:
          valueA = a[sortConfig.key]?.toString().toLowerCase();
          valueB = b[sortConfig.key]?.toString().toLowerCase();
      }

      // Compare the primary values first
      if (valueA < valueB) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }

      // If valueA and valueB are equal, sort by name
      valueA = a.name?.toString().toLowerCase();
      valueB = b.name?.toString().toLowerCase();

      return valueA < valueB
        ? sortConfig.direction === "asc"
          ? -1
          : 1
        : valueA > valueB
        ? sortConfig.direction === "asc"
          ? 1
          : -1
        : 0;
    });
  };
  const filteredSites = useMemo(() => {
    return siteList.filter((site) => {
      const searchTerm = searchQuery.toLowerCase();
      return (
        site.name.toLowerCase().includes(searchTerm) ||
        site.endpoint_status?.status.toLowerCase().includes(searchTerm)
      );
    });
  }, [searchQuery, siteList]);
  return (
    <div>
      <div className="mb-1 ">
        <input
          type="text"
          placeholder="Search sites..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border border-black-300 rounded p-2 w-full"
          style={{ backgroundColor: "#f2f2f2" }}
        />
      </div>

      <table className="table">
        <thead className="sticky top-0 z-10 bg-blue-300">
          <tr>
            <th onClick={() => handleSort("name")} className="cursor-pointer">
              Name
              <FontAwesomeIcon
                icon={
                  sortConfig.key === "name" && sortConfig.direction === "asc"
                    ? faCaretDown
                    : faCaretUp
                }
                className="ml-2"
              />
            </th>
            <th
              onClick={() => handleSort("battery")}
              className="cursor-pointer"
            >
              Battery
              <FontAwesomeIcon
                icon={
                  sortConfig.key === "battery" && sortConfig.direction === "asc"
                    ? faCaretDown
                    : faCaretUp
                }
                className="ml-2"
              />
            </th>
            <th onClick={() => handleSort("status")} className="cursor-pointer">
              Status
              <FontAwesomeIcon
                icon={
                  sortConfig.key === "status" && sortConfig.direction === "asc"
                    ? faCaretDown
                    : faCaretUp
                }
                className="ml-2"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {siteListLoading ? (
            <tr>
              <td colSpan="3">
                <div className="flex items-center justify-center mt-2">
                  <Spinner color="primary" />
                  &nbsp;Loading site groups
                </div>
              </td>
            </tr>
          ) : groups.length > 0 ? (
            groups.map((group, index) => {
              const groupSites = filteredSites?.filter(
                (site) => site.group_name === group
              );
              const sortedsites = sortedSites(groupSites);
              let isAdminUser = siteGroupList?.some(
                (siteGroup) => siteGroup.name === group
              );
              if (user?.is_superuser) {
                isAdminUser = true;
              }
              const isExpanded = expandedGroups.includes(group);
              const indicatorIcon = isExpanded ? faCaretRight : faCaretDown;

              return (
                <React.Fragment key={group + index}>
                  <tr
                    className="cursor-pointer w-full bg-zinc-200 p-2"
                    onClick={() => toggleGroup(group)}
                  >
                    <th className="relative w-full" colSpan="100%">
                      <FontAwesomeIcon icon={indicatorIcon} />{" "}
                      {group || "No group"}
                      {isAdminUser && (
                        <span
                          className="cursor-pointer text-gray-500 text-s absolute right-4 rounded-md hover:text-black px-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddSiteClick(group);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          &nbsp; New Site
                        </span>
                      )}
                    </th>
                  </tr>
                  {!isExpanded &&
                    sortedsites.map((site, i) => (
                      <React.Fragment key={site.id + "" + i}>
                        {!site.archived && (
                          <tr
                            className={`cursor-pointer ${
                              highlightedSite === site.id &&
                              "bg-gray-500 text-white"
                            }`}
                            onClick={() => {
                              openSiteInfo(site.id);
                            }}
                          >
                            <td>
                              <span className="pl-4 font-bold">
                                {site.name}
                              </span>
                            </td>
                            <td>{formatBatteryPercentage(site)}</td>
                            <td>
                              <SiteStatusCircle site={site} />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  {groupSites.length === 0 && isExpanded && (
                    <tr>
                      <td colSpan="3" className="text-center">
                        No sites in this group
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default Sites;
