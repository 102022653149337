import React, { useEffect, useContext, useState } from "react";
import { Button } from "reactstrap";
import AddSiteToGroup from "./AddSiteToGroup.js";
import ListXButton from "../Utilities/ListXButton.js";
import { ModalContext } from "../Contexts/ModalContext";
import { SiteContext } from "../Contexts/SiteContext";
import { SiteGroupContext } from "../Contexts/SiteGroupContext";
import { getCookie } from "../Utilities/CookieUtils.js";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const SiteGroupDetails = ({ selectedGroup, onGroupDelete, siteGroupList }) => {
  const csrfToken = getCookie("redeyeCSRFcookie");
  const { modal, toggleModal, openModal, modalComponent, handleCancel } =
    useContext(ModalContext);
  const { siteList, refreshList, handleSiteSubmit } = useContext(SiteContext);
  const { refreshGroupList } = useContext(SiteGroupContext);

  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    document.title = "Logical Endpoints";
    refreshList(true);
    refreshGroupList();
  }, [refreshList, refreshGroupList, refreshKey]);

  if (!siteGroupList) {
    return null;
  }

  const filteredSites = siteList.filter(
    (site) => site.group == selectedGroup.id
  );

  const handleDeleteSite = (site) => {
    const updatedData = {
      group: null,
      name: site.name,
    };

    $.ajax({
      type: "PUT",
      url: `/redeye/api/sites/${site.id}/`,
      data: JSON.stringify(updatedData),
      contentType: "application/json",
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function () {
        refreshList();
        refreshGroupList();
      },
    });
  };

  const handleDeleteGroup = () => {
    if (window.confirm("Are you sure you want to delete this SiteGroup?")) {
      const groupToDelete = selectedGroup;

      $.ajax({
        type: "DELETE",
        url: `/redeye/api/site_group/${groupToDelete.id}/`,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        success: function () {
          refreshGroupList();
          onGroupDelete(); // Call the onGroupDelete function to notify the parent
        },
      });
    }
  };

  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const handleAddSiteClick = (group) => {
    const newSiteName = prompt("Enter the name for the new site:");
    if (newSiteName === null) {
      return;
    }

    if (newSiteName.trim() === "") {
      alert("Site name cannot be empty.");
      return;
    }
    const newSite = {
      name: newSiteName,
      group: group.id,
    };
    handleSiteSubmit(newSite);
    refreshGroupList();
  };

  return (
    <>
      <h2
        style={{
          backgroundColor: "lightblue",
          padding: "6px",
          borderRadius: "6px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {selectedGroup.name}
        <button
          style={{ border: "none", background: "none", cursor: "pointer" }}
          onClick={handleRefresh}
        >
          <FontAwesomeIcon icon={faRedoAlt} />
        </button>
      </h2>
      <span>Number of Sites: {filteredSites?.length || 0}</span>
      <h5>Sites</h5>
      <div>
        {filteredSites?.length > 0 ? (
          filteredSites.map((site) => (
            <tr key={site.id}>
              <th key={site.id}>{site.name}</th>
              &nbsp; &nbsp; &nbsp;
              <td>
                <ListXButton onClick={() => handleDeleteSite(site)} />
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <th>No sites associated</th>
          </tr>
        )}
      </div>

      <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
        <Button color="primary" onClick={() => openModal("AddSiteToGroup")}>
          Add Existing Site
        </Button>
        <Button
          color="primary"
          onClick={() => handleAddSiteClick(selectedGroup)}
        >
          Add Site
        </Button>
      </div>

      {modal && modalComponent === "AddSiteToGroup" ? (
        <AddSiteToGroup
          siteList={siteList}
          siteGroupList={siteGroupList}
          onCancel={handleCancel}
          selectedGroup={selectedGroup.name}
          refreshList={refreshList}
          toggleModal={toggleModal}
          csrfToken={csrfToken}
          refreshGroupList={refreshGroupList}
        />
      ) : null}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          color="danger"
          onClick={handleDeleteGroup}
          style={{ marginTop: "1rem" }}
        >
          Delete Site Group
        </Button>
      </div>
    </>
  );
};

export default SiteGroupDetails;
