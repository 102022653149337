import React, { useEffect, useState, useContext, Fragment } from "react";
import $ from "jquery";
import SiteDetails from "./SiteDetails.js";
import { formatLatLon } from "../../Utilities.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faMapMarkerAlt,
  faArchive,
  faTrash,
  faEdit,
  faCaretDown,
  faCaretRight,
  faChartLine,
  faList,
  faCamera,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { ModalContext } from "../Contexts/ModalContext";
import {
  useIsSmallPortrait,
  useIsMedium,
  useIsLarge,
  useIsSpecialLarge,
} from "../../MediaQueries.js";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SiteImageUpload from "../ImageUpload/SiteImageUpload";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import SiteGroupDetails from "../SiteGroups/SiteGroupDetails.js";
import SiteHistory from "./SiteHistory.js";
import SiteSerialNumbers from "./SiteSerialNumbers.js";
import RotateImage from "./RotateImage.js";
import CamQrReader from "./CamQrReader.js";
import DetachEndpointModal from "./DetachEndpointModal.js";
import SerialNumberModal from "./SerialNumberModal.js";
import SiteModal from "./SiteModal.js";
import SiteOnMapModal from "./SiteOnMapModal";
import EndpointConfigModal from "./EndpointConfigModal.js";
import ImageViewer from "./ImageViewer";
import EndpointEventViewer from "./EndpointEventViewer";
import { getCookie } from "../Utilities/CookieUtils.js";
import { useToastContext } from "../Contexts/ToastContext.js";
import Notes from "./Notes.js";
import EndpointGraphViewer from "./EndpointGraphViewer.js";

const SiteDetailsManagement = (props) => {
  const [expandedImage, setExpandedImage] = useState(null);
  const {
    adminUser,
    landing,
    sitedeleteHandler,
    siteInfo,
    siteList,
    refreshSiteInfo,
    handleSiteDelete,
    handleSiteSubmit,
    handleSiteEdit,
    imageRotation,
    siteLoading,
    user,
  } = props;
  const [newSiteId, setNewSiteId] = useState(null);
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const { showToast } = useToastContext();
  const [selectedGroup] = useState();

  // state for toggling configJSON
  const [configJsonExpanded, setConfigJsonExpanded] = useState(false);

  const toggleConfigJson = () => {
    setConfigJsonExpanded(!configJsonExpanded);
  };

  const searchFields = [
    { label: "Name", value: "name" },
    { label: "Group", value: "group_name" },
  ];

  const getSiteScrapeUrl = (siteId) => {
    let url = `${window.location.protocol}//${window.location.host}`;
    url += `/redeye/api/scrap/site/${siteId}/`;
    url = url.replace(":3000/", ":8000/"); // dev fix
    return url;
  };

  const preview_images = siteInfo?.image_set?.slice(0, 5).reverse() || []; // Images are sorted on server
  const navigate = useNavigate();
  const { openModal, modal, modalItem, modalComponent, toggleModal } =
    useContext(ModalContext);

  const onSiteModalSave = (item) => {
    toggleModal();
    handleSiteSubmit(item, true, (data) => {
      if (data && data.id) {
        setNewSiteId(data.id);
      }
    });
  };

  const onSiteModalDelete = (item) => {
    toggleModal();
    handleSiteDelete(item);
  };

  const handleSerialNumberSubmit = ({ serial_number, site }) => {
    toggleModal();

    $.ajax({
      type: "POST",
      url: "/redeye/api/serial_numbers/",
      data: { serial_number, site },
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshSiteInfo(site);
      },
      error: function (err) {
        if (err.status === 400) {
          showToast(`Serial number already in use`);
        }
      },
    });
  };

  const handleDetachEndpointSubmit = (item) => {
    console.log("handleDetachEndpointSubmit");
    console.log(item);
    toggleModal();
    let url = "/redeye/api/detach_endpoint/";
    let type = "PUT";

    $.ajax({
      type: type,
      url: url,
      data: {
        id: item.id,
      },
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        refreshSiteInfo(siteInfo.id);
      },
    });
  };

  const handleCancel = (item) => {
    toggleModal();
    item.preventDefault();
  };

  const isSmallPortrait = useIsSmallPortrait();
  const isMedium = useIsMedium();
  const isLarge = useIsLarge();
  const isSpecialLarge = useIsSpecialLarge();

  const adaptiveTitleStyleClass =
    isSmallPortrait || isMedium || isLarge
      ? "text-2xl px-1 py-2 m-0 leading-snug"
      : "text-2xl px-1 py-2 m-0 leading-snug";
  const adaptiveTextStyle =
    isSmallPortrait || isMedium || isLarge ? { fontSize: "0.7em" } : {};
  const largeTextStyle =
    isSmallPortrait || isMedium || isLarge || isSpecialLarge
      ? { fontSize: "1em" }
      : {};

  const imageRotationStyles = {
    transform: `rotate(${imageRotation}deg)`,
  };

  const formatTimestamplocal = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
  };

  const formatTimestampshort = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear().toString().slice(-2)} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  };

  function calculateCountdown(lastUploadTime) {
    const currentTime = Math.round(new Date().getTime() / 1000);
    const lastUploadTimestamp = Math.round(
      new Date(lastUploadTime).getTime() / 1000
    );
    const timeDifference = currentTime - lastUploadTimestamp;
    let timeUntilNextUpload = timeDifference;
    const days = Math.floor(timeUntilNextUpload / (60 * 60 * 24));
    if (days !== 0) {
      timeUntilNextUpload = timeUntilNextUpload - days * 60 * 60 * 24;
    }
    const hours = Math.floor(timeUntilNextUpload / (60 * 60));
    const minutes = Math.floor((timeUntilNextUpload % (60 * 60)) / 60);
    const seconds = Math.floor(timeUntilNextUpload % 60);

    let formattedCountdown;
    if (days === 0) {
      formattedCountdown = `${parseInt(hours)}h ${parseInt(minutes)}m ago`;
    } else {
      formattedCountdown = `${parseInt(days)}d ${parseInt(hours)}h ${parseInt(
        minutes
      )}m ago`;
    }

    return formattedCountdown;
  }

  function useCountdownTimer(lastUploadTime, callbackInterval) {
    const [countdown, setCountdown] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    useEffect(() => {
      {
        /*To update countdown timer*/
      }
      const updateCountdown = () => {
        const currentTime = Math.round(new Date().getTime() / 1000);
        const lastUploadTimestamp = Math.round(
          new Date(lastUploadTime).getTime() / 1000
        );
        {
          /*Finding the Difference between current vs lastCallback time to predict next call back*/
        }
        const timeDifference = currentTime - lastUploadTimestamp;
        let timeUntilNextUpload = timeDifference % (callbackInterval * 60);
        timeUntilNextUpload = callbackInterval * 60 - timeUntilNextUpload;
        {
          /*Logic to add days in countdown if timer is longer than a day*/
        }
        const nextRefresh = timeUntilNextUpload;
        const days = Math.floor(timeUntilNextUpload / (60 * 60 * 24));
        if (days != 0) {
          timeUntilNextUpload = timeUntilNextUpload - days * 60 * 60 * 24;
        }
        const hours = Math.floor(timeUntilNextUpload / (60 * 60));
        const minutes = Math.floor((timeUntilNextUpload % (60 * 60)) / 60);
        var seconds = timeUntilNextUpload;
        if (hours * 60 * 60 + minutes * 60 != 0)
          seconds = Math.floor(
            timeUntilNextUpload % (hours * 60 * 60 + minutes * 60)
          );

        if (days == 0) {
          setCountdown(
            `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
          );
        } else {
          setCountdown(
            `${parseInt(days)}d ${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
          );
        }
        const timeRemaining = callbackInterval * 60 - nextRefresh;

        if (timeRemaining <= 8) {
          setLoading(true);
          const reloadin = 8 - timeRemaining;
          setLoadingMessage(`${reloadin} seconds remaining`);
        } else {
          setLoading(false);
          setLoadingMessage("");
        }

        if (timeRemaining == 8) {
          refreshSiteInfo(siteInfo.id);
        }
      };

      const intervalId = setInterval(updateCountdown, 1000);
      return () => clearInterval(intervalId);
    }, [lastUploadTime, callbackInterval]);
    return { countdown, loading, loadingMessage };
  }

  function useCountdateTimer(lastUploadTime, callbackInterval) {
    const currentTime = Math.round(new Date().getTime() / 1000);
    const lastUploadTimestamp = Math.round(
      new Date(lastUploadTime).getTime() / 1000
    );
    const timeDifference = currentTime - lastUploadTimestamp;
    let timeUntilNextUpload = timeDifference % (callbackInterval * 60);
    timeUntilNextUpload = callbackInterval * 60 - timeUntilNextUpload;
    {
      /*predicted time for next call back and added it to current date, to display date of possible nextcallback*/
    }
    const nextUploadDate = new Date((currentTime + timeUntilNextUpload) * 1000);
    const formattedDate = `${nextUploadDate.getFullYear()}-${(
      nextUploadDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${nextUploadDate
      .getDate()
      .toString()
      .padStart(2, "0")} ${nextUploadDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${nextUploadDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${nextUploadDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return formattedDate;
  }

  var utcDateString = siteInfo?.last_get_config + " UTC";
  var lastGetConfigTime = new Date(utcDateString);
  var callbackInterval =
    siteInfo?.latest_endpoint?.config_on_camera?.callbackTime;
  if (callbackInterval == 0) callbackInterval = 24 * 60;
  const { countdown, loading, loadingMessage } = useCountdownTimer(
    lastGetConfigTime,
    callbackInterval
  );
  const nextcallbackdate = useCountdateTimer(
    lastGetConfigTime,
    callbackInterval
  );

  function useImageCountdownTimers(images) {
    return images.map((item) => calculateCountdown(item.created_on));
  }
  const imageCountdownTimers = useImageCountdownTimers(preview_images);
  let specifier = 0;
  if (
    (siteInfo?.latest_endpoint &&
      siteInfo?.latest_endpoint?.detached_on == null) ||
    siteInfo?.endpoint_serial_number?.length > 0
  ) {
    specifier = 1;
  } else if (
    siteInfo?.image_set?.length > 0 ||
    siteInfo?.last_call_home ||
    siteInfo?.last_get_config
  ) {
    specifier = 2;
  }
  const currentSite = siteList?.find((site) => site.id === siteInfo?.id);
  function convertMinutesToTime(minutes) {
    const hrs = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hrs > 0) {
      return `${hrs} hrs, ${mins} min`;
    } else {
      return `${mins} min`;
    }
  }
  return (
    <div className={`flex flex-col bg-gray-100 h-auto px-3 relative`}>
      {siteInfo && (
        <>
          <>
            <div className="flex items-center justify-between border-b-2 border-slate-300 w-full pt-2">
              {/* Title */}
              <h2 className={`font-bold`}>
                {siteInfo?.name}
                {currentSite?.endpoint_status?.status === "Down" && (
                  <span className="text-red-600 text-base px-4">Site Down</span>
                )}
              </h2>
              <div>
                {/* rename site button */}
                {siteInfo && (user?.is_superuser || adminUser) ? (
                  <button
                    data-tooltip="Rename Site"
                    data-position="left center"
                    onClick={() => {
                      let newName = prompt(
                        "Enter the new name for the site:",
                        siteInfo.name
                      );
                      if (newName !== null) {
                        handleSiteEdit(siteInfo, newName);
                      }
                    }}
                    className="text-blue-500 text-lg mr-2"
                    title="Rename Site"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                ) : null}
                {/* delete site button */}
                {siteInfo && (user?.is_superuser || adminUser) ? (
                  <button
                    onClick={() => {
                      if (specifier != 1) {
                        let confirmation_message =
                          "Are you sure you want to delete site?";
                        if (specifier == 2) {
                          confirmation_message =
                            "Are you sure you want to archive site with Site History?";
                        }
                        if (window.confirm(confirmation_message)) {
                          handleSiteDelete(siteInfo);
                          if (landing == true) {
                            sitedeleteHandler();
                            navigate("/");
                          } else {
                            navigate("/sites?tab=sites");
                          }
                        }
                      } else {
                        let confirmation_message =
                          "Can not archive site with attached Endpoint";
                        if (siteInfo?.endpoint_serial_number?.length > 0) {
                          confirmation_message =
                            "Can not archive site with attached Serial Number";
                        }
                        if (window.confirm(confirmation_message)) {
                          if (landing == true) {
                            navigate("/");
                          } else {
                            navigate("/sites?tab=sites");
                          }
                        }
                      }
                    }}
                    className="text-red-500 text-lg mr-2"
                    title="Delete Site"
                  >
                    {specifier === 0 ? (
                      <span
                        data-tooltip="Delete Site"
                        data-position="left center"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </span>
                    ) : (
                      <span
                        data-tooltip="Archive Site"
                        data-position="left center"
                      >
                        <FontAwesomeIcon icon={faArchive} />
                      </span>
                    )}
                  </button>
                ) : null}
              </div>
            </div>
            {/* subtitle */}
            <div className="flex justify-between w-full py-2">
              <div>
                <b>GPS:</b>
                {formatLatLon(siteInfo.latitude, siteInfo.longitude)}
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  onClick={() => openModal("SiteOnMapModal", siteInfo)}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                  color="#DB4437"
                />
              </div>
              {currentSite?.endpoint_status?.latest_endpoint_diagnostics
                ?.battery_percentage != null && (
                <span className="text-base">
                  Last Reported Battery:{" "}
                  <span className="font-bold">
                    {Math.floor(
                      currentSite.endpoint_status?.latest_endpoint_diagnostics
                        .battery_percentage
                    )}
                    %
                  </span>
                </span>
              )}
            </div>

            {siteInfo.latest_endpoint &&
            siteInfo.latest_endpoint.maintenance_mode ? (
              <div className="border-gray-300 border-2 rounded-md shadow-md text-xl p-3 bg-white">
                <h4>Maintenance Mode: On</h4>
                <span>
                  Modem will remain active. Endpoint will not power off.
                </span>
              </div>
            ) : null}

            <div className="flex-1 flex-col">
              <SiteImageUpload site={siteInfo} />
              <div className="py-4">
                {preview_images.length > 0 ? (
                  <div
                    className={
                      "flex flex-row items-center overflow-hidden" +
                      (preview_images.length > 4
                        ? " justify-content-between"
                        : " justify-start")
                    }
                  >
                    {preview_images.map((item, index) => (
                      <Fragment key={index}>
                        <div
                          className="relative"
                          onClick={() => {
                            setExpandedImage(item);
                          }}
                        >
                          <div className="overflow-hidden rounded-sm shadow-md">
                            <img
                              key={item.id}
                              src={item.thumbnail_url}
                              alt={item.image_name}
                              className={`h-auto w-full inline-block cursor-pointer`}
                              style={{
                                ...imageRotationStyles,
                              }}
                            />
                          </div>
                          {/* Countdown timer tooltip */}
                          <div className="absolute top-0 left-0 right-0 w-full bg-black flex items-center justify-center px-1 py-0">
                            <span
                              className="hidden md:block text-white text-sm text-center"
                              data-tooltip={imageCountdownTimers[index]}
                              data-position="bottom center"
                            >
                              {formatTimestampshort(item.created_on)}
                            </span>
                          </div>
                        </div>
                        {/* Render chevron icon if not the last item */}
                        {index < preview_images.length - 1 ? (
                          <FontAwesomeIcon
                            className="h-auto w-[15px] px-3 opacity-65 hidden md:block"
                            icon={faChevronRight}
                          />
                        ) : null}
                      </Fragment>
                    ))}
                  </div>
                ) : null}
              </div>

              {/* buttons */}
              <div className="flex w-full flex-col gap-4 md:flex-row justify-between py-3 border-t-2 ">
                <RotateImage siteInfo={siteInfo} />
                {!siteInfo.latest_endpoint ||
                siteInfo.latest_endpoint.detached_on != null ? (
                  <>
                    <SiteSerialNumbers siteInfo={siteInfo} fullList={false} />
                    {(user?.is_superuser || adminUser) && (
                      <Button
                        color="primary"
                        onClick={() => openModal("SerialNumberModal", siteInfo)}
                      >
                        Register endpoint
                      </Button>
                    )}
                    {modal && modalComponent === "SerialNumberModal" ? (
                      <SerialNumberModal
                        initialItem={modalItem}
                        toggle={toggleModal}
                        onSave={handleSerialNumberSubmit}
                        onCancel={handleCancel}
                        openModal={openModal}
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    <Button
                      color="primary"
                      onClick={() => openModal("EndpointConfigModal")}
                    >
                      <FontAwesomeIcon icon={faClock} />
                      &nbsp; Callback Time
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => openModal("ShowEndpointGraphViewer")}
                    >
                      <FontAwesomeIcon icon={faChartLine} />
                      &nbsp; Graphs
                    </Button>
                    {modal && modalComponent === "ShowEndpointGraphViewer" ? (
                      <EndpointGraphViewer
                        endpointId={siteInfo?.latest_endpoint?.id}
                        toggle={toggleModal}
                        lastCallback={
                          siteInfo?.latest_endpoint?.last_save_config_at
                        }
                      />
                    ) : null}

                    <Button
                      color="primary"
                      onClick={() => openModal("ShowEndpointEventViewer")}
                    >
                      <FontAwesomeIcon icon={faList} />
                      &nbsp; Events
                    </Button>
                    {modal && modalComponent === "ShowEndpointEventViewer" ? (
                      <EndpointEventViewer
                        endpointId={siteInfo?.latest_endpoint?.id}
                        toggle={toggleModal}
                        lastCallback={
                          siteInfo?.latest_endpoint?.last_save_config_at
                        }
                      />
                    ) : null}

                    {modal && modalComponent === "SiteModal" ? (
                      <SiteModal
                        initialItem={modalItem}
                        toggle={toggleModal}
                        onSave={onSiteModalSave}
                        onDelete={onSiteModalDelete}
                        onCancel={handleCancel}
                      />
                    ) : null}
                    {modal && modalComponent === "CamQrReader" ? (
                      <CamQrReader
                        toggle={toggleModal}
                        openModal={openModal}
                        siteInfo={modalItem}
                      />
                    ) : null}
                    {modal && modalComponent === "DetachEndpointModal" ? (
                      <DetachEndpointModal
                        initialItem={modalItem}
                        toggle={toggleModal}
                        onSave={handleDetachEndpointSubmit}
                      />
                    ) : null}
                    {modal && modalComponent === "SiteOnMapModal" ? (
                      <SiteOnMapModal siteInfo={modalItem} />
                    ) : null}
                    {modal && modalComponent === "EndpointConfigModal" ? (
                      <EndpointConfigModal
                        siteInfo={siteInfo}
                        toggle={toggleModal}
                        onCancel={handleCancel}
                      />
                    ) : null}
                    {modal && modalComponent === "ImageViewer" ? (
                      <ImageViewer
                        siteInfo={siteInfo}
                        toggle={toggleModal}
                        imageRotation={imageRotation}
                        refreshSiteInfo={refreshSiteInfo}
                      />
                    ) : null}
                    {siteInfo?.image_set?.length > 0 ? (
                      <Button
                        color="primary"
                        onClick={() => openModal("ImageViewer")}
                      >
                        <FontAwesomeIcon icon={faCamera} />
                        &nbsp; Images
                      </Button>
                    ) : (
                      <Button color="secondary" disabled>
                        No images
                      </Button>
                    )}
                    <Button
                      onClick={() => toggleConfigJson()}
                      color="secondary"
                    >
                      <FontAwesomeIcon
                        className="pr-2"
                        icon={configJsonExpanded ? faCaretDown : faCaretRight}
                      />
                      Camera Config
                    </Button>
                  </>
                )}
                {/* <SiteHistory siteInfo={siteInfo} /> */}
              </div>

              <>
                {configJsonExpanded ? (
                  <pre className="whitespace-pre-wrap rounded-sm shadow-sm bg-slate-700 p-3 mt-0.5 text-white text-base">
                    Config from camera last saved at
                    {new Date(
                      siteInfo.latest_endpoint.last_save_config_at
                    ).toLocaleString()}
                    <br />
                    {JSON.stringify(
                      siteInfo.latest_endpoint.config_on_camera,
                      undefined,
                      2
                    )}
                  </pre>
                ) : null}
              </>

              <Notes />

              {siteInfo.latest_endpoint &&
              siteInfo.latest_endpoint.detached_on == null ? (
                <>
                  <div className="flex py-2 w-full">
                    <table className="table bg-white border">
                      <tbody>
                        {siteInfo?.endpoint_serial_number && (
                          <tr>
                            <td className="font-bold">Serial Id</td>
                            <td>{siteInfo?.endpoint_serial_number}</td>
                          </tr>
                        )}
                        {siteInfo.last_image_upload && (
                          <tr>
                            <td className="font-bold">Last Callback</td>
                            <td>{formatTimestamplocal(lastGetConfigTime)}</td>
                          </tr>
                        )}
                        <tr>
                          {currentSite?.endpoint_status?.status === "Down" ? (
                            <>
                              <td className="font-bold">Site down!</td>
                              <td>
                                <span>
                                  {currentSite.endpoint_status?.message}
                                </span>
                              </td>
                            </>
                          ) : loading ? (
                            <>
                              <td className="font-bold">Loading...</td>
                              <td>
                                <span>{loadingMessage}</span>
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="font-bold">Next Callback</td>
                              <td>
                                <span>
                                  {nextcallbackdate} (<b>in</b> {countdown})
                                </span>
                              </td>
                            </>
                          )}
                        </tr>
                        {siteInfo?.latest_endpoint?.config_on_camera
                          ?.callbackTime && (
                          <tr>
                            <td className="font-bold">Callback Time</td>
                            <td>
                              {siteInfo?.latest_endpoint?.callback_time} minutes{" "}
                              {siteInfo?.latest_endpoint?.config_on_camera
                                ?.callbackTime !==
                                siteInfo?.latest_endpoint?.callback_time && (
                                <>
                                  (
                                  {
                                    siteInfo?.latest_endpoint?.config_on_camera
                                      ?.callbackTime
                                  }
                                  {" minutes until next check-in"})
                                </>
                              )}
                            </td>
                          </tr>
                        )}
                        {siteInfo?.latest_endpoint?.config_on_camera
                          ?.pictureTime && (
                          <tr>
                            <td className="font-bold">Picture Time</td>
                            <td>
                              {siteInfo?.latest_endpoint?.picture_time} minutes{" "}
                              {siteInfo?.latest_endpoint?.config_on_camera
                                ?.pictureTime !==
                                siteInfo?.latest_endpoint?.picture_time && (
                                <>
                                  (
                                  {
                                    siteInfo?.latest_endpoint?.config_on_camera
                                      ?.pictureTime
                                  }
                                  {" minutes on next picture"})
                                </>
                              )}
                            </td>
                          </tr>
                        )}
                        {siteInfo?.latest_endpoint?.maintenance_mode_link && (
                          <tr>
                            <td className="font-bold">Last Maintenance IP</td>
                            <td>
                              <a
                                href={`http://${siteInfo?.latest_endpoint?.maintenance_mode_link}:8082`}
                              >
                                {siteInfo?.latest_endpoint?.maintenance_mode_link
                                  .split(" ")
                                  .shift()}
                              </a>
                            </td>
                          </tr>
                        )}
                        {siteInfo.endpoint_set.map((item) => {
                          return (
                            item.detached_on === null && (
                              <Fragment key={item.id}>
                                <tr>
                                  <td className="font-bold">
                                    Camera Software Version
                                  </td>
                                  <td>{item.version_number}</td>
                                </tr>
                              </Fragment>
                            )
                          );
                        })}
                        {siteInfo.endpoint_set.map((item) => {
                          return (
                            item.detached_on === null && (
                              <Fragment key={item.id}>
                                <tr>
                                  <td className="font-bold">IP Info</td>
                                  <td>{item.endpoint_url}</td>
                                </tr>
                              </Fragment>
                            )
                          );
                        })}
                        <tr>
                          <td className="font-bold">Registered on</td>
                          <td>{siteInfo?.latest_endpoint.created_on}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  {siteInfo?.serialnumber_set?.length > 0 &&
                  !siteInfo.serialnumber_set[0].used &&
                  !siteInfo.serialnumber_set[0].valid ? (
                    <p>
                      Endpoint registration process timed out. You will need to
                      resubmit a serial number to register an endpoint for this
                      site.
                    </p>
                  ) : null}
                  {siteInfo.operation_logs?.length > 0 &&
                    siteInfo.operation_logs.map((log) => (
                      <>
                        {log.relevant_to_registration ? (
                          <p>
                            {log.created_on} - {log.message}
                          </p>
                        ) : null}
                      </>
                    ))}
                  <table className="table bg-white mt-4 rounded-sm shadow-sm border-2 border-zinc-400">
                    <tbody>
                      <tr>
                        <td className="text-center font-bold">
                          No endpoint currently connected at this site
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}

              <div className="w-full">
                {siteInfo?.latest_endpoint?.config_on_camera &&
                Object.keys(siteInfo.latest_endpoint.config_on_camera)
                  .length ? (
                  <>
                    <div className="pt-3 pb-5 w-full text-base flex flex-col gap-2">
                      {siteInfo && (
                        <a>Data loaded on {siteInfo.loadedOnDate}</a>
                      )}

                      {siteInfo && (
                        <a href={getSiteScrapeUrl(siteInfo?.id)}>
                          Image Scrape: {getSiteScrapeUrl(siteInfo?.id)}
                        </a>
                      )}

                      {(user?.is_superuser || adminUser) && (
                        <a
                          onClick={() =>
                            openModal("DetachEndpointModal", siteInfo)
                          }
                          className="cursor-pointer"
                        >
                          Detach endpoint
                        </a>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/* style={index === activeIndex ? highlightedThumbnailStyle : ""} */}

            {expandedImage && (
              <Modal
                isOpen={true}
                toggle={() => setExpandedImage(null)}
                style={{ maxWidth: "90%", height: "90vh" }}
              >
                <ModalHeader toggle={() => setExpandedImage(null)}>
                  {expandedImage.image_name}
                </ModalHeader>
                <ModalBody
                  style={{
                    height: "90vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={expandedImage.image_url}
                    alt={expandedImage.image_name}
                    className="w-full h-auto max-w-[800px]"
                    style={{
                      ...imageRotationStyles,
                    }}
                  />
                </ModalBody>
              </Modal>
            )}
            {modal && modalComponent === "SiteDetails" ? (
              <SiteDetails siteInfo={siteInfo} />
            ) : null}
          </>
        </>
      )}

      {/* default text */}
      <>
        {!siteInfo && !siteLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {" "}
            <span>Select a site to see the details!</span>
          </div>
        )}

        {selectedGroup && (
          <div>
            <SiteGroupDetails selectedGroup={selectedGroup} />
          </div>
        )}
      </>

      {siteLoading && (
        <div
          className="absolute top-0 bottom-0 left-0 right-0 bg-black/70
                  flex justify-center items-center flex-col gap-3 text-white min-h-[800px] fade-in"
        >
          <div>Loading...</div>
          <div
            className="animate-spin inline-block size-8 border-[3px] border-current border-t-transparent rounded-full text-white"
            role="status"
            aria-label="loading"
          ></div>
        </div>
      )}
    </div>
  );
};

export default SiteDetailsManagement;
