import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { formatLatLon } from "../../Utilities.js";
import { ModalContext } from "../Contexts/ModalContext";

const SiteDetails = (props) => {
  const { siteInfo } = props;

  const { openModal } = useContext(ModalContext);

  const lineStyle = {
    lineHeight: "0.2",
  };

  return (
    <div>
      <p style={lineStyle}>
        <b>GPS:</b> {formatLatLon(siteInfo.latitude, siteInfo.longitude)}
        &nbsp;
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          onClick={() => openModal("SiteOnMapModal", siteInfo)}
          style={{ cursor: "pointer" }}
          color="#DB4437"
        />
      </p>
      <h6 style={lineStyle}>
        {siteInfo.latest_endpoint?.name}{" "}
        {siteInfo.latest_endpoint &&
        siteInfo.latest_endpoint.maintenance_mode ? (
          <>
            in maintenance mode{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="orange"
              className="bi bi-circle-fill"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
              <text
                x="50%"
                y="50%"
                text-anchor="middle"
                stroke="black"
                fill="black"
                stroke-width="0.1px"
                dy=".3em"
              >
                M
              </text>
            </svg>
          </>
        ) : null}
      </h6>

      {siteInfo.latest_endpoint &&
      siteInfo.latest_endpoint.detached_on == null ? (
        <>
          <p style={lineStyle}>
            <b>Registered on:</b> {siteInfo?.latest_endpoint.created_on}
          </p>
          <p style={lineStyle}>
            <b>Endpoint Model</b>{" "}
            {siteInfo.latest_endpoint.model
              ? siteInfo.latest_endpoint.model
              : "V-cam.v1.0"}
          </p>
        </>
      ) : (
        <p style={lineStyle}>No endpoint connected</p>
      )}
    </div>
  );
};
export default SiteDetails;
