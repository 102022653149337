import React, { useEffect, useState, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import $ from "jquery";

import Header from "./Header.js";
import Navbar from "./Navbar.js";
import Landing from "./Tabs/Landing.js";
import SitesAndSiteGroupsManagement from "./Tabs/SitesAndSiteGroupsManagement.js";
import UsersAndUserGroupsManagement from "./Tabs/UsersAndUserGroupsManagement.js";
import CompaniesManagement from "./Tabs/CompaniesManagement.js";

import { ManagementContext } from "./Contexts/ManagementContext.js";
import { CompanyContext } from "./Contexts/CompanyContext.js";

const Management = ({ logoutHandler }) => {
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);

  const { user } = useContext(ManagementContext);
  const { userCompany } = useContext(CompanyContext);

  useEffect(() => {
    if (user && userCompany) {
      const userCompanyData = userCompany.filter(
        (item) => item.user === user.id && item.is_companyadmin === true
      );
      setIsCompanyAdmin(
        userCompanyData.some((item) => item.is_companyadmin) ||
          user?.is_superuser
      );
    }
  }, [user, userCompany]);

  return (
    <>
      <Header logoutHandler={logoutHandler} isCompanyAdmin={isCompanyAdmin} />
      {/*<Navbar user={user} isCompanyAdmin={isCompanyAdmin} />*/}
      <Routes>
        <Route path="/" element={<Landing isCompanyAdmin={isCompanyAdmin} />} />
        <Route
          path="/id/:id"
          element={<Landing isCompanyAdmin={isCompanyAdmin} />}
        />
        <Route path="/sites" element={<SitesAndSiteGroupsManagement />} />
        <Route
          path="/sites/id/:id"
          element={<SitesAndSiteGroupsManagement />}
        />
        {(user?.is_superuser || isCompanyAdmin) && (
          <Route
            path="/users"
            element={<UsersAndUserGroupsManagement user={user} />}
          />
        )}
        {user?.is_superuser && (
          <Route path="/companies" element={<CompaniesManagement />} />
        )}
      </Routes>
    </>
  );
};

export default Management;
