import React, { useEffect, useState, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { getCookie } from "../Utilities/CookieUtils.js";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const EndpointEventViewer = ({ endpointId, toggle, lastCallback }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [storedLastCallback, setstoredLastCallback] = useState(lastCallback);
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    // Only update if checkin time changes
    if (lastCallback != storedLastCallback) {
      console.log("Updating last callback");
      setstoredLastCallback(lastCallback);
      getEvents();
    }
  }, [lastCallback]);

  const getEvents = () => {
    $.ajax({
      type: "GET",
      url: "/redeye/endpoint_events/" + endpointId,
      data: endpointId,
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        setLoading(false);
        setLogs(data);
      },
    });
  };

  const rowColor = (index, severity) => {
    let color = "";

    if (index % 2 == 0) {
      color = "#eee"; // Normal
    }
    if (severity == 1) {
      color = "#C3EDBF"; // Good
    }
    if (severity == 2) {
      color = "#B8DFE6"; // Alright
    }
    if (severity == 3) {
      color = "#F9EFC7"; // Decent
    }
    if (severity == 4) {
      color = "#FDD0B1"; // Not Good
    }
    if (severity == 5) {
      color = "#FBB9C5"; // VERY BAD!
    }

    return color;
  };

  const formatTimestamp = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleString();
  };

  const timeAgo = (incoming) => {
    if (!incoming) {
      return "";
    }

    let date = new Date(incoming);
    const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 },
    ];

    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find((i) => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
  };

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      className="w-full m-[1px] md:m-auto md:!max-w-[80%]"
    >
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Event Viewer - Last 1000 Events - Last event{" "}
        <span>{timeAgo(logs[0]?.date)}</span>
      </ModalHeader>
      <div className="modal-dialog-scrollable" style={{ height: "90vh" }}>
        <ModalBody style={{ maxHeight: "90vh", overflowY: "auto" }}>
          {loading ? (
            <div className="flex items-center justify-center h-full w-full">
              <Spinner color="primary" />
            </div>
          ) : (
            <div className="flex flex-col relative">
              <div className="grid grid-cols-3 px-3 py-2 font-bold">
                <div>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-slate-400 pr-4"
                  />
                  Date
                </div>
                <div>Event</div>
                <div>Message</div>
              </div>
              {logs.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 md:grid-cols-3 px-3 py-2 "
                  style={{ backgroundColor: rowColor(index, item.severity) }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="text-slate-400 pr-4"
                    />
                    {formatTimestamp(item.date)}
                  </div>
                  <div>{item.event_name}</div>
                  {item.message && (
                    <div className="text-center col-span-2 md:col-span-1 border-t-2 border-t-gray-300 md:border-none">
                      {item.message}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default EndpointEventViewer;
