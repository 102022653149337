import React from "react";

const SiteStatusCircle = (props) => {
  const { site } = props;

  let color = [
    "bg-white",
    "bg-red-500",
    "bg-amber-300",
    "bg-green-500",
    "bg-zinc-300",
  ];
  if (site.endpoint_status?.color) {
    color = site.endpoint_status?.color;
  }
  let status = site.endpoint_status?.status;
  let message = site.endpoint_status?.message;

  return (
    <div data-tooltip={message} data-position="left center">
      <span className={`circle-icon ${color}`}></span>
      <span>&nbsp;</span>
      <span>{status}</span>
    </div>
  );
};

export default SiteStatusCircle;
