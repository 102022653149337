import React, { useEffect, useState, Fragment } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import $ from "jquery";
import { getCookie } from "../Utilities/CookieUtils.js";

const EndpointGraphViewer = ({ endpointId, toggle, lastCallback }) => {
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const [selectedGraphs, setSelectedGraphs] = useState(["Battery Percentage"]); // default selected graph
  const [lastRecentValidData, setLastRecentValidData] = useState([]);
  const [totalDataPoints, setTotalDataPoints] = useState(90);
  const [storedLastCallback, setstoredLastCallback] = useState(lastCallback);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getChartData();
  }, []);

  useEffect(() => {
    // Only update if checkin time changes
    if (lastCallback != storedLastCallback) {
      getChartData(totalDataPoints);
    }
  }, [lastCallback]);

  const getChartData = (totalDataPoints = 90) => {
    setTotalDataPoints(totalDataPoints);
    setLoading(true);
    $.ajax({
      type: "POST",
      url: "/redeye/endpoint_graph_data/" + endpointId,
      data: { endpointId, totalDataPoints },
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
      success: function (data) {
        //   setLoading(false);

        function ucwords(str) {
          return (str + "")
            .replaceAll("_", " ")
            .replace(/^([a-z])|\s+([a-z])/g, function ($1) {
              return $1.toUpperCase();
            });
        }

        let formattedData = [];
        data.forEach((row) => {
          let formattedRow = {};
          // Remove dashes, capitalize letters and cast each entry as float
          Object.keys(row).forEach((rowName) => {
            formattedRow[`${ucwords(rowName)}`] = parseFloat(row[rowName]);
          });

          // x value determines location on graph based on date
          formattedRow["x"] = new Date(row["checkin_time"]).getTime();
          formattedData.push(formattedRow);
        });

        setLoading(false);
        setLastRecentValidData(formattedData);
      },
    });
  };

  //Converts a UTC timestamp (stored in database as check_in time) to a local timestamp
  const formatTimestamp = (timestamp) => {
    let date = new Date(timestamp);
    return date.toLocaleString();
  };

  // Each value is backend vaariable name converted to readable key
  // eg battery_percentage => Battery Percentage
  const legendConfig = {
    "Battery Percentage": ["%", "green"],
    "Battery Voltage": ["mAH", "red"],
    "Box Temperature": ["°C", "blue"],
    // "Camera Current": ["mAH", "cyan"],
    "Cpu Temperature": ["°C", "purple"],
    "Cpu Usage": ["%", "teal"],
    "HDD Space": ["GB", "yellow"],
    Humidity: ["%", "slateblue"],
    // "Load Current": ["mAH", "dodgerblue"],
    // "Modem Current": ["mAH", "orange"],
    "Ram Usage": ["GB", "silver"],
    // "Solar Current": ["mAH", "fuchsia"],
    // "Solar Voltage": ["mAH", "purple"],
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="rounded-md shadow-lg bg-white text-xs border border-blue-950 p-3">
          <p key={"x"} className="label w-full min-w-[160px]">
            {formatTimestamp(payload[0].payload["x"])}
          </p>
          {selectedGraphs.map((key) => (
            <div className="flex justify-between" key={key}>
              <span>{key}</span>
              <span className="font-bold">
                {`${payload[0].payload[key] ?? "NaN"} ${
                  legendConfig[key][0] || ""
                }`}
              </span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderDot = (props) => (
    <circle cx={props.cx} cy={props.cy} r={3} fill={props.stroke} />
  );

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      className="w-full m-[1px] md:m-auto md:!max-w-[80%]"
    >
      <ModalHeader toggle={toggle} style={{ backgroundColor: "#d3d3d3" }}>
        Site Diagnostics
      </ModalHeader>
      <ModalBody>
        {loading ?? (
          <div className="flex items-center justify-center h-full w-full">
            <Spinner color="primary" />
          </div>
        )}

        {lastRecentValidData.length && !loading ? (
          <>
            <div className="flex flex-col-reverse md:flex-row">
              <div>
                <div className="flex gap-2">
                  <div
                    className={
                      "w-full rounded-sm text-center text-white cursor-pointer " +
                      (totalDataPoints == 30 ? "bg-green-400" : "bg-slate-400")
                    }
                    onClick={() => getChartData(30)}
                  >
                    30
                  </div>
                  <div
                    className={
                      "w-full rounded-sm text-center text-white cursor-pointer " +
                      (totalDataPoints == 60 ? "bg-green-400" : "bg-slate-400")
                    }
                    onClick={() => getChartData(60)}
                  >
                    60
                  </div>
                  <div
                    className={
                      "w-full rounded-sm text-center text-white cursor-pointer " +
                      (totalDataPoints == 90 ? "bg-green-400" : "bg-slate-400")
                    }
                    onClick={() => getChartData(90)}
                  >
                    90
                  </div>
                  <div
                    className={
                      "w-full rounded-sm text-center text-white cursor-pointer " +
                      (totalDataPoints == 5000
                        ? "bg-green-400"
                        : "bg-slate-400")
                    }
                    onClick={() => getChartData(5000)}
                  >
                    All
                  </div>
                </div>

                {Object.keys(legendConfig).map((key) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 5,
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        width: 10,
                        height: 10,
                        backgroundColor: legendConfig[key][1],
                        marginRight: 5,
                      }}
                    ></span>
                    <input
                      type="checkbox"
                      id={`checkbox-${key}`}
                      checked={selectedGraphs.includes(key)}
                      onChange={() => {
                        if (selectedGraphs.includes(key)) {
                          setSelectedGraphs((prev) =>
                            prev.filter((k) => k !== key)
                          );
                        } else {
                          setSelectedGraphs((prev) => [...prev, key]);
                        }
                      }}
                    />
                    <label
                      htmlFor={`checkbox-${key}`}
                      style={{ marginLeft: 5 }}
                    >
                      {key}
                    </label>
                  </div>
                ))}
              </div>

              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={lastRecentValidData}>
                  <CartesianGrid stroke="#ccc" />
                  <XAxis
                    dataKey="x"
                    type="number"
                    domain={["auto", "auto"]}
                    tickFormatter={(tickItem) =>
                      new Date(tickItem).toLocaleDateString()
                    }
                  />
                  <YAxis hide={false} />
                  <Tooltip
                    content={<CustomTooltip />}
                    cursor={{ stroke: "red" }}
                  />

                  {Object.keys(legendConfig).map((key, index) =>
                    selectedGraphs.includes(key) ? (
                      <Line
                        key={key + index}
                        dataKey={key}
                        stroke={legendConfig[key][1]}
                        strokeDasharray="4 4"
                        dot={renderDot}
                      />
                    ) : (
                      ""
                    )
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : (
          <div>No diagnostic data available.</div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default EndpointGraphViewer;
