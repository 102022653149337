import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Marker,
  MapContainer,
  Popup,
  TileLayer,
  Tooltip,
  useMapEvents,
  useMap,
} from "react-leaflet";
import { Button } from "reactstrap";
import SiteDetails from "./SiteDetails.js";
import { SiteIcon } from "../Utilities/CustomIcon.js";
import { ManagementContext } from "../Contexts/ManagementContext.js";

import { useNavigate } from "react-router-dom";

const Map = (props) => {
  const {
    siteList,
    openModal,
    siteInfo,
    highlightedSite,
    sidebarIsOpen,
    siteSelectHandler,
  } = props;
  const { user } = useContext(ManagementContext);

  const markerRefs = useRef({});

  const [selectedSite, setSelectedSite] = useState(null);

  const navigate = useNavigate();

  const markerEventHandlers = {
    click: (e) => {
      if (selectedSite && markerRefs.current[selectedSite]) {
        markerRefs.current[selectedSite].closePopup();
      }

      const site = siteList.find((item) => item.id === e.target.options.data);
      // add a flag to indicate site is opened from Map
      site.openedFromMap = true;
      if (selectedSite === site.id) {
        markerRefs.current[site.id].openPopup();
      } else {
        setSelectedSite(site.id);
      }
    },
    popupclose: (e) => {
      setSelectedSite(null);
    },
  };

  const handleMapClickEvent = (e) => {
    if (user) {
      openModal("SiteModal", {
        name: "",
        latitude: e.latlng["lat"].toFixed(8).toString(),
        longitude: e.latlng["lng"].toFixed(8).toString(),
      });
    }
  };

  const mapContainerStyle = {
    width: "100%",
    height: `calc(100vh - 85px)`,
    borderLeft: "3px solid black",
    zIndex: "0",
  };

  // @TODO - Define location based on site groups or users location, not hard coded
  const [centerLat, setCenterLat] = useState(0);
  const [centerLng, setCenterLng] = useState(0);

  useEffect(() => {
    if (siteList && siteList.length > 0) {
      /* 
        proximity threshold is a value that determines how close two sites
        need to be to each other to be considered part of the same cluster.

        In Geographic Terms:
          It’s usually measured in degrees of latitude and longitude.
          1 degree of latitude is approximately 111 kilometers (69 miles).
      */

      // use a 10-mile threshold to determine proximity and group sites into clusters
      // 10 miles to degrees: degrees = 10 (miles) / 69 (miles/degree) = 0.145 degrees
      const proximity_threshold = 0.145;
      const clusters = [];

      // create clusters by grouping sites that are close to each other
      siteList.forEach((siteA) => {
        let foundCluster = false;

        for (const cluster of clusters) {
          for (const siteB of cluster) {
            const latitudeDiff = Math.abs(siteA.latitude - siteB.latitude);
            const longitudeDiff = Math.abs(siteA.longitude - siteB.longitude);

            if (
              latitudeDiff < proximity_threshold &&
              longitudeDiff < proximity_threshold
            ) {
              cluster.push(siteA);
              foundCluster = true;
              break;
            }
          }
          if (foundCluster) {
            break;
          }
        }

        if (!foundCluster) {
          clusters.push([siteA]);
        }
      });

      // find the cluster with the most sites
      const largestCluster = clusters.reduce((largest, cluster) => {
        return cluster.length > largest.length ? cluster : largest;
      }, []);

      // calculate the center of the largest cluster
      let totalLat = 0;
      let totalLng = 0;

      largestCluster.forEach((site) => {
        totalLat += parseFloat(site.latitude);
        totalLng += parseFloat(site.longitude);
      });

      const centerLat = totalLat / largestCluster.length;
      const centerLng = totalLng / largestCluster.length;

      setCenterLat(centerLat);
      setCenterLng(centerLng);
    }
  }, [siteList]);

  const h5Style = {
    backgroundColor: "lightblue",
    padding: "5px",
    borderRadius: "5px",
    color: "#db4437",
  };

  useEffect(() => {
    if (!sidebarIsOpen && selectedSite && markerRefs.current[selectedSite]) {
      markerRefs.current[selectedSite].closePopup();
      setSelectedSite(null);
    }
  }, [sidebarIsOpen]);

  return (
    <div className="hidden md:block">
      <MapContainer
        style={mapContainerStyle}
        center={[centerLat, centerLng]}
        zoom={siteList.length > 0 ? 10 : 12}
        scrollWheelZoom={true}
        data-testid={"map-container"}
      >
        <TileLayer
          attribution='Kartendaten: &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>-Mitwirkende, SRTM | Kartendarstellung: &copy; <a href="http://opentopomap.org">OpenTopoMap</a> <a href="https://creativecommons.org/licenses/by-sa/3.0/">(CC-BY-SA)</a>'
          url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
        />
        {siteList.filter((item) => item.latitude !== null).length > 0
          ? siteList
              .filter((item) => item.latitude !== null)
              .map((item) => (
                <Marker
                  icon={SiteIcon(item, highlightedSite)}
                  key={item.id}
                  data={item.id}
                  position={[item.latitude, item.longitude]}
                  eventHandlers={markerEventHandlers}
                  interactive
                  ref={(ref) => (markerRefs.current[item.id] = ref)}
                  data-testid={"TestName1"}
                  riseOnHover
                >
                  <Tooltip>{item.name}</Tooltip>
                  {siteInfo ? (
                    <Popup>
                      <div className="d-flex flex-column">
                        <h5 style={h5Style}>Site: {siteInfo.name}</h5>
                        <SiteDetails siteInfo={siteInfo} />
                      </div>
                      {user && user.is_superuser ? (
                        <Button
                          color="primary"
                          onClick={() => {
                            siteSelectHandler(siteInfo?.id);
                          }}
                        >
                          More Info
                        </Button>
                      ) : null}
                    </Popup>
                  ) : (
                    <Popup />
                  )}
                </Marker>
              ))
          : null}
        <MapContent
          handleMapClickEvent={handleMapClickEvent}
          highlightedSite={highlightedSite}
          siteList={siteList}
          markerRefs={markerRefs}
          center={[centerLat, centerLng]}
        />
      </MapContainer>
    </div>
  );
};

const MapContent = (props) => {
  const { handleMapClickEvent, highlightedSite, siteList, markerRefs, center } =
    props;

  const map = useMap();

  useEffect(() => {
    map.setView(center);
  }, [center, map]);

  useMapEvents({
    click(e) {
      handleMapClickEvent(e);
    },
  });

  const [lastOpenedPopup, setLastOpenedPopup] = useState(null);

  useEffect(() => {
    const site = siteList.find((obj) => {
      return obj.id === highlightedSite;
    });
    if (site != null) {
      const zoomLevel = 15;
      map.flyTo([site.latitude, site.longitude], zoomLevel);
      if (markerRefs.current[lastOpenedPopup]) {
        markerRefs.current[lastOpenedPopup].closePopup();
      }
      setLastOpenedPopup(site.id);
    } else {
      for (let key in markerRefs?.current) {
        markerRefs?.current[key]?.closePopup();
      }
      setLastOpenedPopup(null);
    }
  }, [highlightedSite, siteList, map, markerRefs, lastOpenedPopup]);
};

export default Map;
