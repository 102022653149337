import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faExpand } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

import { useToastContext } from "../Contexts/ToastContext";
import { getCookie } from "../Utilities/CookieUtils";
import { ModalContext } from "../Contexts/ModalContext";
import SiteImageModal from "./SiteImageModal";
import ImageModal from "../Sites/ImageModal";

const SiteImageUpload = ({ site }) => {
  const [currentImage, setCurrentImage] = useState(site.site_image_path);
  const [csrfToken] = useState(getCookie("redeyeCSRFcookie"));
  const { showToast } = useToastContext();
  const { openModal, modal, modalComponent } = useContext(ModalContext);

  // handler function for the deletion of site image
  const handleDeleteImage = async () => {
    try {
      await $.ajax({
        type: "POST",
        url: `/redeye/api/delete_site_image/${site.id}/`,
        headers: { "X-CSRFToken": csrfToken },
        xhrFields: { withCredentials: true },
        success: function (response) {
          showToast(response.message);
          setCurrentImage(null);
        },
        error: function (xhr, textStatus, errorThrown) {
          showToast(`Error deleting the image: ${errorThrown}`);
        },
      });
    } catch (error) {
      showToast(`Error deleting the image: ${error.message}`);
    }
  };

  // load initial image preview if site.site_image_path exists
  useEffect(() => {
    if (site.site_image_path) {
      // TODO: this needs to be fixed, but for time being keeping as it is
      setCurrentImage(`${site.site_image_path}`);
    } else {
      setCurrentImage(site.site_image_path);
    }
  }, [site]);

  // function to open the modal with image options
  const openSiteImageModalHandler = () => {
    openModal("SiteImageModal", {
      setCurrentImage,
    });
  };

  return (
    <>
      <div className="relative w-auto h-full max-h-[350px] overflow-hidden flex justify-center items-center bg-gray-200">
        {currentImage ? (
          <>
            <img
              src={currentImage}
              alt="Image Preview"
              className="w-full md:w-[50%] h-auto object-cover"
            />
            <div className="absolute top-1 right-1 flex gap-2">
              <button
                className="bg-white rounded-full p-1 cursor-pointer text-black"
                onClick={() =>
                  openModal("SiteImageZoomModal", {
                    imageUrl: currentImage,
                  })
                }
                data-tooltip="Zoom Site Image"
                data-position="left center"
              >
                <FontAwesomeIcon icon={faExpand} size="1x" />
              </button>
              <button
                className="bg-white rounded-full p-1 cursor-pointer"
                onClick={openSiteImageModalHandler}
                data-tooltip="Edit Site Image"
                data-position="left center"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                className="bg-white rounded-full p-1 cursor-pointer"
                onClick={() => {
                  let confirmation_message =
                    "Are you sure you want to delete the site image?";
                  if (window.confirm(confirmation_message)) {
                    handleDeleteImage();
                  }
                }}
                data-tooltip="Delete Site Image"
                data-position="left center"
              >
                <FontAwesomeIcon icon={faTrash} className="text-red-500" />
              </button>
            </div>
          </>
        ) : (
          <div className="relative flex items-center justify-center w-full h-4/5 bg-gray-200 border-gray-400 p-4">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded"
              onClick={openSiteImageModalHandler}
            >
              Upload Site Image
            </button>
          </div>
        )}
      </div>
      {modal && modalComponent === "SiteImageModal" ? (
        <SiteImageModal currentImagePreview={currentImage} site={site} />
      ) : null}
      {modal && modalComponent === "SiteImageZoomModal" ? (
        <ImageModal imageUrl={currentImage} imageName={`${site.full_name}`} />
      ) : null}
    </>
  );
};

export default SiteImageUpload;
