import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useContext,
} from "react";
import $ from "jquery";

import { ManagementContext } from "./ManagementContext";

export const CompanyContext = createContext();

export const CompanyProvider = (props) => {
  const [companyList, setCompanyList] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const { user } = useContext(ManagementContext);

  // Fetch company list based on user data
  const refreshCompanyList = useCallback(() => {
    if (!user) {
      setCompanyList([]);
      return;
    }

    let url = "/redeye/api/company/";

    $.get(url, {
      xhrFields: { withCredentials: true },
    }).done(function (data) {
      $.get(`/redeye/api/user_company/`, {
        xhrFields: { withCredentials: true },
      }).done(function (usercompanydata) {
        setUserCompany(usercompanydata);
        if (!user.is_superuser) {
          const associatedCompanies = usercompanydata.filter(
            (item) => item.user === user.id && item.is_companyadmin === true
          );
          const associatedCompanyIds = associatedCompanies.map(
            (item) => item.company
          );
          const filteredCompanies = data.filter((company) =>
            associatedCompanyIds.includes(company.id)
          );
          setCompanyList(filteredCompanies);
        } else {
          setCompanyList(data);
        }
      });
    });
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        userCompany,
        companyList,
        setCompanyList,
        refreshCompanyList,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};
