import React, { useState, useEffect, useRef, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import $ from "jquery";

import { getCookie } from "../Utilities/CookieUtils.js";
import { SiteContext } from "../Contexts/SiteContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const Notes = () => {
  const { siteInfo } = useContext(SiteContext);

  // state for notes
  const [notes, setNotes] = useState(siteInfo?.latest_endpoint?.notes || "");

  // state for tracking whether the notes field is in focus
  const [notesInFocus, setNotesInFocus] = useState(false);

  // state for tracking characters typed count
  const maxChars = 3000;
  const [charCount, setCharCount] = useState(0);

  // state for showing the different variations on save button
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [notesSaved, setNotesSaved] = useState(false);

  const editorRef = useRef(null);

  const defaultEditorHeight = 100;

  const csrfToken = getCookie("redeyeCSRFcookie");

  // storing the notes in local storage temporarily
  const localStorageKey = `unsavedNotes_${
    siteInfo?.latest_endpoint?.id || "default"
  }`;

  const orderedEndpoints =
    siteInfo &&
    [...siteInfo.endpoint_set].sort(
      (a, b) => new Date(b.created_on) - new Date(a.created_on)
    );

  useEffect(() => {
    // if the Notes component is rendered for the first time,
    // remove the notes from local storage
    localStorage.removeItem(localStorageKey);
  }, []);

  useEffect(() => {
    // retrieve unsaved notes from local storage
    const savedNotes = localStorage.getItem(localStorageKey);
    if (savedNotes) {
      setNotes(savedNotes);
      setCharCount(savedNotes.replace(/<[^>]+>/g, "").length);
    } else {
      setNotes(siteInfo?.latest_endpoint?.notes || "");
      setCharCount(
        (siteInfo?.latest_endpoint?.notes || "").replace(/<[^>]+>/g, "").length
      );
    }
  }, [siteInfo]);

  const handleNotesChange = (content) => {
    // strip HTML tags and count characters
    const contentLength = content.replace(/<[^>]+>/g, "").length;
    if (contentLength <= maxChars) {
      setNotes(content);
      setCharCount(contentLength);
      updateEditorHeight();

      // save the notes to local storage
      localStorage.setItem(localStorageKey, content);
    }
  };

  const updateEditorHeight = () => {
    const editor = editorRef.current?.getEditor();
    if (editor) {
      const editorContainer = editor.root;

      // reset to auto to recalculate height
      editorContainer.style.height = "auto";

      // set height to scrollHeight
      editorContainer.style.height = `${Math.max(
        defaultEditorHeight,
        editorContainer.scrollHeight
      )}px`;
    }
  };

  const onSave = (notes) => {
    const url = `/redeye/api/endpoints/${siteInfo.latest_endpoint.id}/`;
    const type = "PUT";

    $.ajax({
      type: type,
      url: url,
      data: {
        notes: notes,
      },
      headers: { "X-CSRFToken": csrfToken },
      xhrFields: { withCredentials: true },
      dataType: "json",
    })
      .then(() => {
        setSaveInProgress(false);
        setNotesSaved(true);
        setTimeout(() => {
          setNotesSaved(false);
        }, 4000);
      })
      .fail((jqXHR, textStatus) => {
        console.error(`AJAX error: ${textStatus}`);
        setSaveInProgress(false);
      });
  };

  const handleSave = () => {
    if (notes !== siteInfo?.latest_endpoint?.notes) {
      // sanitize the notes content before sending to backend
      const sanitizedNotes = DOMPurify.sanitize(notes);

      setSaveInProgress(true);

      // save the notes
      onSave(sanitizedNotes);

      // remove the focus
      setNotesInFocus(false);

      // clear the notes from local storage after saving
      localStorage.removeItem(localStorageKey);
    }
  };

  const handleBeforeUnload = (event) => {
    if (notesInFocus && notes !== siteInfo?.latest_endpoint?.notes) {
      event.preventDefault();
      event.returnValue = "";

      // clear notes from localStorage if the user confirms leaving the page
      localStorage.removeItem(localStorageKey);
    }
  };

  useEffect(() => {
    if (notesInFocus) {
      // update height when notes are expanded
      updateEditorHeight();
    }

    // add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [notesInFocus, notes]);

  const isSaveDisabled =
    notes === siteInfo?.latest_endpoint?.notes || saveInProgress || notesSaved;

  return (
    <>
      {orderedEndpoints && orderedEndpoints.length > 0 ? (
        <div className="w-full mt-2 mb-2">
          <ReactQuill
            value={notes}
            onChange={handleNotesChange}
            onFocus={() => setNotesInFocus(true)} // Set to true on focus
            onBlur={() => setNotesInFocus(false)} // Set to false on blur (focus lost)
            placeholder="Notes about Endpoint"
            className="text-black w-full mt-2 bg-white"
            ref={editorRef}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link"],
                ["clean"],
              ],
            }}
          />
          <div className="w-full flex justify-between items-center">
            <button
              onClick={handleSave}
              className={`mt-2 px-4 py-2 rounded ${
                isSaveDisabled
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
              disabled={isSaveDisabled}
            >
              <FontAwesomeIcon icon={faSave} />
              &nbsp;
              {!saveInProgress && !notesSaved
                ? "Save note"
                : saveInProgress
                ? "Saving..."
                : "Saved"}
            </button>
            <div
              className={`text-sm mt-1 ${
                charCount >= maxChars ? "text-red-500" : "text-gray-600"
              }`}
            >
              {charCount}/{maxChars}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Notes;
